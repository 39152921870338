<template>
  <div class="submission">
    <div class="join-desc">
      <div class="title">
        <span class="line"></span> {{ $t("cansaixuzhi_title") }}
      </div>
      <div class="content">
        <div>{{ $t("cansaixuzhi") }}</div>
        <div>{{ $t("cansaixuzhi_1") }}</div>
        <div>{{ $t("cansaixuzhi_2") }}</div>
        <div>{{ $t("cansaixuzhi_3") }}</div>
        <div>{{ $t("cansaixuzhi_4") }}</div>
        <div>{{ $t("cansaixuzhi_5") }}</div>
        <div>{{ $t("cansaixuzhi_6") }}</div>
      </div>
    </div>
    <div class="tougao">
      <div class="title">
        <span class="line"></span> {{ $t("tougaozuopin") }}
      </div>
      <div class="submit-form">
        <el-form
          :model="dynamicValidateForm"
          ref="dynamicValidateForm"
          label-width="100px"
          class="demo-dynamic"
        >
          <el-form-item
            class="vertical"
            prop="name"
            :label="$t('productName')"
            :rules="[
              { required: true, message: $t('productName'), trigger: 'blur' },
            ]"
          >
            <el-input v-model="dynamicValidateForm.name"></el-input>
          </el-form-item>
          <el-form-item
            class="repeat-form-item vertical"
            v-for="(team, index) in dynamicValidateForm.teamMembers"
            :label="$t('teamInfo') + (index + 1)"
            :key="'team-' + index"
            :rules="[
              {
                required: true,
                message: $t('teamInfo'),
                trigger: 'blur',
              },
            ]"
          >
            <div class="repeat-form-subitem">
              <el-form-item
                :key="'team-item-name' + index"
                :prop="'teamMembers.' + index + '.name'"
                :label="$t('username')"
                :rules="[
                  {
                    required: true,
                    message: $t('username'),
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input v-model="team.name"></el-input>
              </el-form-item>
              <!-- :rules="[
                  { validator: contactPhone, required: true, trigger: 'blur' },
                ]" -->
              <el-form-item
                :key="'team-item-contact' + index"
                :prop="'teamMembers.' + index + '.contact'"
                :label="$t('userphone')"
              >
                <el-input v-model="team.contact"></el-input>
              </el-form-item>
              <el-form-item
                :key="'team-item-school' + index"
                :prop="'teamMembers.' + index + '.school'"
                :label="$t('institutions')"
                :rules="[
                  {
                    required: true,
                    message: $t('institutions'),
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input v-model="team.school"></el-input>
              </el-form-item>
              <div
                class="delete"
                v-if="index !== 0"
                @click="deleteTeamMember(index)"
              >
                <i class="el-icon-delete"></i>
              </div>
            </div>
            <!-- <el-input v-model="domain.value"></el-input>
            <el-input v-model="domain.value"></el-input> -->
            <!-- <el-button @click.prevent="removeDomain(domain)">删除</el-button> -->
          </el-form-item>
          <el-form-item class="add-btn">
            <!-- <el-button @click="addTeamMember()"> -->
            <el-image
              class="add-team-member-img"
              fit="fill"
              :src="
                $t('addTeam') === 'Add Team Members'
                  ? require('@/static/images/add-e.png')
                  : require('@/static/images/add.png')
              "
              alt=""
              @click="addTeamMember()"
            ></el-image>
            <!-- </el-button> -->
          </el-form-item>
          <el-form-item
            class="repeat-form-item vertical"
            :label="$t('teacherInfo')"
          >
            <div class="repeat-form-subitem">
              <el-form-item
                prop="teacherName"
                :label="$t('username')"
                :rules="[
                  {
                    required: true,
                    message: $t('username'),
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input v-model="dynamicValidateForm.teacherName"></el-input>
              </el-form-item>
              <!-- :rules="[
                  {
                    validator: contactPhone,
                    required: true,
                    trigger: 'blur',
                  },
                ]" -->
              <el-form-item
                prop="teacherPhone"
                :label="$t('userphone')"
              >
                <el-input v-model="dynamicValidateForm.teacherPhone"></el-input>
              </el-form-item>
              <el-form-item
                prop="teacherSchool"
                :label="$t('institutions')"
                :rules="[
                  {
                    required: true,
                    message: $t('institutions'),
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  v-model="dynamicValidateForm.teacherSchool"
                ></el-input>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item
            class="zuopin-upload vertical"
            :label="$t('entryDisplay')"
            key="zuopin"
            prop="imgUrl"
            :rules="[
              {
                required: true,
                message: $t('entryDisplay'),
                trigger: 'blur',
              },
              {
                required: true,
                message: $t('entryDisplay'),
                trigger: 'change',
              },
            ]"
          >
            <!-- :rules="{
              required: true,
              message: '作品不能为空',
              trigger: 'change',
            }" -->
            <div class="desc">
              <div>
                {{ $t("layout1") }}
              </div>
              <div>
                {{ $t("layout2") }}
              </div>
            </div>
            <!-- action="https://jsonplaceholder.typicode.com/posts/" -->
            <el-upload
              class="upload"
              action="http://api.yhmwdesign.com/api/upload"
              multiple
              accept="image/jpeg, image/jpeg, image/png"
              :limit="1"
              :file-list="fileList"
              :on-success="handleUploadSuccess"
            >
              <el-image
                class="add-team-member-img"
                fit="fill"
                :src="
                  $t('uploadImage') === 'Upload Image File'
                    ? require('@/static/images/upload-e.png')
                    : require('@/static/images/upload.png')
                "
                alt=""
              ></el-image>
              <!-- <el-button size="small" type="primary">点击上传</el-button> -->
              <div slot="tip" class="el-upload__tip">
                <p>{{ $t("uploadlimit") }}</p>
                <p v-if="$t('uploadDesc')">
                  {{ $t("uploadDesc") }}
                  <span style="color: #ff5500; font-weight: 600;"
                    >395199768@qq.com</span
                  >
                </p>
              </div>
            </el-upload>
            <el-image
              style="transform: translateX(20vw);width: 25vw"
              v-if="dynamicValidateForm.imgUrl"
              fit="fill"
              :src="dynamicValidateForm.imgUrl"
            ></el-image>
          </el-form-item>

          <el-form-item
            class="zuopin-upload vertical"
            :label="$t('thumbnail')"
            key="thumbnail"
            prop="imgUrl"
            :rules="[
              {
                required: true,
                message: $t('thumbnail'),
                trigger: 'blur',
              },
              {
                required: true,
                message: $t('thumbnail'),
                trigger: 'change',
              },
            ]"
          >
            <el-upload
              class="upload"
              action="http://api.yhmwdesign.com/api/upload"
              multiple
              accept="image/jpeg, image/jpeg, image/png"
              :limit="1"
              :file-list="thumbnailList"
              :on-success="handleThumbnailUploadSuccess"
            >
              <el-image
                class="add-team-member-img"
                fit="fill"
                :src="
                  $t('uploadImage') === 'Upload Image File'
                    ? require('@/static/images/thumbnail-e.png')
                    : require('@/static/images/thumbnail.png')
                "
                alt=""
              ></el-image>
              <!-- <el-button size="small" type="primary">点击上传</el-button> -->
              <div slot="tip" class="el-upload__tip">
                <p>{{ $t("thumbnailLimit") }}</p>
                <p v-if="$t('uploadDesc')">
                  {{ $t("uploadDesc") }}
                  <span style="color: #ff5500; font-weight: 600;"
                    >395199768@qq.com</span
                  >
                </p>
              </div>
            </el-upload>
            <el-image
              style="transform: translateX(20vw);width: 25vw"
              v-if="dynamicValidateForm.thumbnail"
              fit="fill"
              :src="dynamicValidateForm.thumbnail"
            ></el-image>
          </el-form-item>

          <el-form-item
            class="vertical"
            :label="$t('entryDescription')"
            :placeholder="$t('entryDescription')"
            prop="title"
            :rules="{
              required: true,
              message: $t('entryDescription'),
              trigger: 'blur',
            }"
          >
            <el-input
              type="textarea"
              v-model="dynamicValidateForm.title"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="xieyi">
      <el-checkbox v-model="checked">{{ $t("agreenment") }}</el-checkbox>
      <div class="submit-btn" @click="submit()">
        {{ $t("save") }}
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="50%"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div slot="title">
        <p style="font-size: 24px;margin: 0 0 10px 0">提示</p>
        <p style="font-size: 18px;margin: 0 0 10px 0">Notification</p>
      </div>
      <p style="font-size: 20px; margin: -10px 0 0 0">
        2022年(第六届)浙江云和木玩创意设计大赛，<br />
        已于2022年10月8日 24:00(北京时间)截止征集！<br />感谢您的支持与理解！
      </p>
      <p style="font-size: 14px; margin: 20px 0 0 0">
        The submission channel of 2022 ZHEJIANG YUNHE WOODEN TOY CREATIVE DESIGN
        COMPETITION <br />
        has been closed, Thanks for your participation !
      </p>
    </el-dialog>
  </div>
</template>

<script>
import ApiService from "@/api/apiService";
import API from "@/api";

export default {
  name: "index",
  data() {
    return {
      dialogVisible: false,
      workId: null,
      checked: false,
      fileList: [],
      thumbnailList: [],
      dynamicValidateForm: {
        teamMembers: [
          {
            name: "",
            contact: "",
            school: "",
          },
        ],
        name: "",
        title: "",
        imgUrl: "",
        teacherName: "",
        teacherPhone: "",
        teacherSchool: "",
        thumbnail: "",
      },
    };
  },
  components: {},
  watch: {},
  mounted() {
    // if (!localStorage.getItem('token')) {
    //   this.$message.error('您还未登陆，请登录！');
    //   setTimeout(() => {
    //     this.$router.push('/login');
    //   }, 500);
    // }
    this.workId = this.$route.query.id;
    if (this.workId) {
      this.getWorkInfo();
    }
  },
  methods: {
    async getWorkInfo() {
      const result = await ApiService.get(API.workInfo + this.workId);
      this.dynamicValidateForm = {
        ...result.data,
      };
      this.fileList = [
        {
          name: result.data.imgUrl,
          url: result.data.imgUrl,
        },
      ];
      this.thumbnailList = [
        {
          name: result.data.thumbnail,
          url: result.data.thumbnail,
        },
      ];
    },
    submit() {
      if (!this.checked) {
        this.$message.warning("请勾选赛事公告");
        return false;
      }
      this.$refs["dynamicValidateForm"].validate(async (valid) => {
        if (valid) {
          if (this.workId) {
            await ApiService.post(API.updateWork, {
              ...this.dynamicValidateForm,
              groupMember: JSON.stringify(this.dynamicValidateForm.teamMembers),
              imgUrl: this.fileList[0]?.url || "",
              thumbnail: this.thumbnailList[0]?.url || "",
            });
          } else {
            await ApiService.post(API.addWorks, {
              ...this.dynamicValidateForm,
              groupMember: JSON.stringify(this.dynamicValidateForm.teamMembers),
              imgUrl: this.fileList[0]?.url || "",
              thumbnail: this.thumbnailList[0]?.url || "",
            });
          }

          this.$message.success("提交成功");
          this.$router.push("/home");
        } else {
          this.$message.error("请输入完整信息");
          return false;
        }
      });
    },

    handleUploadSuccess(res) {
      this.fileList = [
        {
          name: res.data,
          url: res.data,
        },
      ];
      this.dynamicValidateForm.imgUrl = res.data;
    },

    handleThumbnailUploadSuccess(res) {
      this.thumbnailList = [
        {
          name: res.data,
          url: res.data,
        },
      ];
      this.dynamicValidateForm.thumbnail = res.data;
    },
    addTeamMember() {
      this.dynamicValidateForm.teamMembers.push({
        name: "",
        contact: "",
        school: "",
      });
    },
    deleteTeamMember(index) {
      this.dynamicValidateForm.teamMembers.splice(index, 1);
    },
    // 手机号码验证
    contactPhone(rule, value, callback) {
      if (!value) {
        return callback(new Error(this.$t("userphone")));
      } else {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
.submission {
  .demo-dynamic {
    margin: 3vw 0;
  }
  .join-desc,
  .tougao {
    .title {
      height: 50px;
      line-height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background: #eaeaea;
      .line {
        margin: 0 1.4vw;
        display: inline-block;
        width: 4px;
        height: 1.4vw;
        line-height: 1.4vw;
        border: 1px solid #1a2043;
      }
    }
  }
  .join-desc {
    .title {
      border-radius: 6px;
    }
    .content {
      margin-top: 10px;
      text-align: justify;
    }
  }
  .tougao {
    margin: 2.1vw 0;
    border: 12px solid #eaeaea;
    border-radius: 6px;
    /deep/.repeat-form-item {
      .el-form-item__content {
        margin-left: 0 !important;
        .repeat-form-subitem {
          display: flex;
          .el-form-item__content {
            margin-left: 7vw !important;
          }
          .el-form-item__label {
            text-align: right;
            font-size: 1vw;
          }
          .delete {
            margin-left: 2.1vw;
          }
        }
      }
    }

    .submit-form {
      padding: 0 2.1vw;
    }

    /deep/.add-btn {
      border-top: 1px solid #eaeaea;
      padding-top: 1.388vw;
      .el-form-item__content {
        margin-left: 0px !important;
      }
    }

    /deep/.vertical {
      display: flex;
      flex-direction: column;
      .el-form-item__content {
        margin-left: 0px !important;
      }
      .el-form-item__label {
        text-align: left;
        font-size: 16px;
        font-weight: 600;
        color: #1a2043;
        white-space: nowrap;
      }
    }

    /deep/.add-team-member-img {
      img {
        width: 16vw;
      }
    }
    .zuopin-upload {
      text-align: left;
      .upload {
        text-align: center;
        margin-top: 1.4vw;
      }
      .desc {
        text-align: justify;
      }
    }
  }

  .xieyi {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.1vw;
    font-weight: 300;
    color: #595959;
    line-height: 1.67vw;

    .submit-btn {
      margin: 2vw 0 3vw;
      width: 20vw;
      height: 3vw;
      cursor: pointer;
      background: #eee7d8;
      border-radius: 3px;
      text-align: center;
      line-height: 3vw;
    }
  }
}
</style>
