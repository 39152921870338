var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"submission"},[_c('div',{staticClass:"join-desc"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"line"}),_vm._v(" "+_vm._s(_vm.$t("cansaixuzhi_title"))+" ")]),_c('div',{staticClass:"content"},[_c('div',[_vm._v(_vm._s(_vm.$t("cansaixuzhi")))]),_c('div',[_vm._v(_vm._s(_vm.$t("cansaixuzhi_1")))]),_c('div',[_vm._v(_vm._s(_vm.$t("cansaixuzhi_2")))]),_c('div',[_vm._v(_vm._s(_vm.$t("cansaixuzhi_3")))]),_c('div',[_vm._v(_vm._s(_vm.$t("cansaixuzhi_4")))]),_c('div',[_vm._v(_vm._s(_vm.$t("cansaixuzhi_5")))]),_c('div',[_vm._v(_vm._s(_vm.$t("cansaixuzhi_6")))])])]),_c('div',{staticClass:"tougao"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"line"}),_vm._v(" "+_vm._s(_vm.$t("tougaozuopin"))+" ")]),_c('div',{staticClass:"submit-form"},[_c('el-form',{ref:"dynamicValidateForm",staticClass:"demo-dynamic",attrs:{"model":_vm.dynamicValidateForm,"label-width":"100px"}},[_c('el-form-item',{staticClass:"vertical",attrs:{"prop":"name","label":_vm.$t('productName'),"rules":[
            { required: true, message: _vm.$t('productName'), trigger: 'blur' } ]}},[_c('el-input',{model:{value:(_vm.dynamicValidateForm.name),callback:function ($$v) {_vm.$set(_vm.dynamicValidateForm, "name", $$v)},expression:"dynamicValidateForm.name"}})],1),_vm._l((_vm.dynamicValidateForm.teamMembers),function(team,index){return _c('el-form-item',{key:'team-' + index,staticClass:"repeat-form-item vertical",attrs:{"label":_vm.$t('teamInfo') + (index + 1),"rules":[
            {
              required: true,
              message: _vm.$t('teamInfo'),
              trigger: 'blur',
            } ]}},[_c('div',{staticClass:"repeat-form-subitem"},[_c('el-form-item',{key:'team-item-name' + index,attrs:{"prop":'teamMembers.' + index + '.name',"label":_vm.$t('username'),"rules":[
                {
                  required: true,
                  message: _vm.$t('username'),
                  trigger: 'blur',
                } ]}},[_c('el-input',{model:{value:(team.name),callback:function ($$v) {_vm.$set(team, "name", $$v)},expression:"team.name"}})],1),_c('el-form-item',{key:'team-item-contact' + index,attrs:{"prop":'teamMembers.' + index + '.contact',"label":_vm.$t('userphone')}},[_c('el-input',{model:{value:(team.contact),callback:function ($$v) {_vm.$set(team, "contact", $$v)},expression:"team.contact"}})],1),_c('el-form-item',{key:'team-item-school' + index,attrs:{"prop":'teamMembers.' + index + '.school',"label":_vm.$t('institutions'),"rules":[
                {
                  required: true,
                  message: _vm.$t('institutions'),
                  trigger: 'blur',
                } ]}},[_c('el-input',{model:{value:(team.school),callback:function ($$v) {_vm.$set(team, "school", $$v)},expression:"team.school"}})],1),(index !== 0)?_c('div',{staticClass:"delete",on:{"click":function($event){return _vm.deleteTeamMember(index)}}},[_c('i',{staticClass:"el-icon-delete"})]):_vm._e()],1)])}),_c('el-form-item',{staticClass:"add-btn"},[_c('el-image',{staticClass:"add-team-member-img",attrs:{"fit":"fill","src":_vm.$t('addTeam') === 'Add Team Members'
                ? require('@/static/images/add-e.png')
                : require('@/static/images/add.png'),"alt":""},on:{"click":function($event){return _vm.addTeamMember()}}})],1),_c('el-form-item',{staticClass:"repeat-form-item vertical",attrs:{"label":_vm.$t('teacherInfo')}},[_c('div',{staticClass:"repeat-form-subitem"},[_c('el-form-item',{attrs:{"prop":"teacherName","label":_vm.$t('username'),"rules":[
                {
                  required: true,
                  message: _vm.$t('username'),
                  trigger: 'blur',
                } ]}},[_c('el-input',{model:{value:(_vm.dynamicValidateForm.teacherName),callback:function ($$v) {_vm.$set(_vm.dynamicValidateForm, "teacherName", $$v)},expression:"dynamicValidateForm.teacherName"}})],1),_c('el-form-item',{attrs:{"prop":"teacherPhone","label":_vm.$t('userphone')}},[_c('el-input',{model:{value:(_vm.dynamicValidateForm.teacherPhone),callback:function ($$v) {_vm.$set(_vm.dynamicValidateForm, "teacherPhone", $$v)},expression:"dynamicValidateForm.teacherPhone"}})],1),_c('el-form-item',{attrs:{"prop":"teacherSchool","label":_vm.$t('institutions'),"rules":[
                {
                  required: true,
                  message: _vm.$t('institutions'),
                  trigger: 'blur',
                } ]}},[_c('el-input',{model:{value:(_vm.dynamicValidateForm.teacherSchool),callback:function ($$v) {_vm.$set(_vm.dynamicValidateForm, "teacherSchool", $$v)},expression:"dynamicValidateForm.teacherSchool"}})],1)],1)]),_c('el-form-item',{key:"zuopin",staticClass:"zuopin-upload vertical",attrs:{"label":_vm.$t('entryDisplay'),"prop":"imgUrl","rules":[
            {
              required: true,
              message: _vm.$t('entryDisplay'),
              trigger: 'blur',
            },
            {
              required: true,
              message: _vm.$t('entryDisplay'),
              trigger: 'change',
            } ]}},[_c('div',{staticClass:"desc"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("layout1"))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t("layout2"))+" ")])]),_c('el-upload',{staticClass:"upload",attrs:{"action":"http://api.yhmwdesign.com/api/upload","multiple":"","accept":"image/jpeg, image/jpeg, image/png","limit":1,"file-list":_vm.fileList,"on-success":_vm.handleUploadSuccess}},[_c('el-image',{staticClass:"add-team-member-img",attrs:{"fit":"fill","src":_vm.$t('uploadImage') === 'Upload Image File'
                  ? require('@/static/images/upload-e.png')
                  : require('@/static/images/upload.png'),"alt":""}}),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_c('p',[_vm._v(_vm._s(_vm.$t("uploadlimit")))]),(_vm.$t('uploadDesc'))?_c('p',[_vm._v(" "+_vm._s(_vm.$t("uploadDesc"))+" "),_c('span',{staticStyle:{"color":"#ff5500","font-weight":"600"}},[_vm._v("395199768@qq.com")])]):_vm._e()])],1),(_vm.dynamicValidateForm.imgUrl)?_c('el-image',{staticStyle:{"transform":"translateX(20vw)","width":"25vw"},attrs:{"fit":"fill","src":_vm.dynamicValidateForm.imgUrl}}):_vm._e()],1),_c('el-form-item',{key:"thumbnail",staticClass:"zuopin-upload vertical",attrs:{"label":_vm.$t('thumbnail'),"prop":"imgUrl","rules":[
            {
              required: true,
              message: _vm.$t('thumbnail'),
              trigger: 'blur',
            },
            {
              required: true,
              message: _vm.$t('thumbnail'),
              trigger: 'change',
            } ]}},[_c('el-upload',{staticClass:"upload",attrs:{"action":"http://api.yhmwdesign.com/api/upload","multiple":"","accept":"image/jpeg, image/jpeg, image/png","limit":1,"file-list":_vm.thumbnailList,"on-success":_vm.handleThumbnailUploadSuccess}},[_c('el-image',{staticClass:"add-team-member-img",attrs:{"fit":"fill","src":_vm.$t('uploadImage') === 'Upload Image File'
                  ? require('@/static/images/thumbnail-e.png')
                  : require('@/static/images/thumbnail.png'),"alt":""}}),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_c('p',[_vm._v(_vm._s(_vm.$t("thumbnailLimit")))]),(_vm.$t('uploadDesc'))?_c('p',[_vm._v(" "+_vm._s(_vm.$t("uploadDesc"))+" "),_c('span',{staticStyle:{"color":"#ff5500","font-weight":"600"}},[_vm._v("395199768@qq.com")])]):_vm._e()])],1),(_vm.dynamicValidateForm.thumbnail)?_c('el-image',{staticStyle:{"transform":"translateX(20vw)","width":"25vw"},attrs:{"fit":"fill","src":_vm.dynamicValidateForm.thumbnail}}):_vm._e()],1),_c('el-form-item',{staticClass:"vertical",attrs:{"label":_vm.$t('entryDescription'),"placeholder":_vm.$t('entryDescription'),"prop":"title","rules":{
            required: true,
            message: _vm.$t('entryDescription'),
            trigger: 'blur',
          }}},[_c('el-input',{attrs:{"type":"textarea"},model:{value:(_vm.dynamicValidateForm.title),callback:function ($$v) {_vm.$set(_vm.dynamicValidateForm, "title", $$v)},expression:"dynamicValidateForm.title"}})],1)],2)],1)]),_c('div',{staticClass:"xieyi"},[_c('el-checkbox',{model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_vm._v(_vm._s(_vm.$t("agreenment")))]),_c('div',{staticClass:"submit-btn",on:{"click":function($event){return _vm.submit()}}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")])],1),_c('el-dialog',{attrs:{"visible":_vm.dialogVisible,"width":"50%","show-close":false,"close-on-click-modal":false,"close-on-press-escape":false},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('p',{staticStyle:{"font-size":"24px","margin":"0 0 10px 0"}},[_vm._v("提示")]),_c('p',{staticStyle:{"font-size":"18px","margin":"0 0 10px 0"}},[_vm._v("Notification")])]),_c('p',{staticStyle:{"font-size":"20px","margin":"-10px 0 0 0"}},[_vm._v(" 2022年(第六届)浙江云和木玩创意设计大赛，"),_c('br'),_vm._v(" 已于2022年10月8日 24:00(北京时间)截止征集！"),_c('br'),_vm._v("感谢您的支持与理解！ ")]),_c('p',{staticStyle:{"font-size":"14px","margin":"20px 0 0 0"}},[_vm._v(" The submission channel of 2022 ZHEJIANG YUNHE WOODEN TOY CREATIVE DESIGN COMPETITION "),_c('br'),_vm._v(" has been closed, Thanks for your participation ! ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }